<template>
  <b-modal id="guest-pay-confirmation-modal"
           ref="guest-pay-confirmation-modal"
           title="Payment Confirmed"
           size="md"
           hide-footer
           v-on="$listeners"
  >

    <div v-if="invoice" class="text-center">
      <p> A charge for {{ invoice.total | currency }} will appear on your statement from {{ invoice.billingName ? invoice.billingName : '"Corporate Filings LLC"' }}.</p>

      <p>Your confirmation number # is: {{ confirmationNumber }}</p>

      <p class="mb-0">If you have any questions, please give us a call at </p><p v-phone-number="invoice.contact.phone.phoneNumber"></p>

      <p>Thank you!</p>
    </div>
  </b-modal>
</template>

<script>
import { phoneNumber } from '@/directives/directives'

export default {
  name: 'GuestPayConfirmationModal',
  directives: {
    phoneNumber,
  },
  props: {
    invoice: {
      type: Object,
      value: {},
    },
    confirmationNumber: {
      type: String,
      value: '',
    },
  },
}
</script>
