var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    _vm._g(
      {
        ref: "guest-pay-confirmation-modal",
        attrs: {
          id: "guest-pay-confirmation-modal",
          title: "Payment Confirmed",
          size: "md",
          "hide-footer": "",
        },
      },
      _vm.$listeners
    ),
    [
      _vm.invoice
        ? _c("div", { staticClass: "text-center" }, [
            _c("p", [
              _vm._v(
                " A charge for " +
                  _vm._s(_vm._f("currency")(_vm.invoice.total)) +
                  " will appear on your statement from " +
                  _vm._s(
                    _vm.invoice.billingName
                      ? _vm.invoice.billingName
                      : '"Corporate Filings LLC"'
                  ) +
                  "."
              ),
            ]),
            _c("p", [
              _vm._v(
                "Your confirmation number # is: " +
                  _vm._s(_vm.confirmationNumber)
              ),
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v("If you have any questions, please give us a call at "),
            ]),
            _c("p", {
              directives: [
                {
                  name: "phone-number",
                  rawName: "v-phone-number",
                  value: _vm.invoice.contact.phone.phoneNumber,
                  expression: "invoice.contact.phone.phoneNumber",
                },
              ],
            }),
            _c("p", [_vm._v("Thank you!")]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }